import { Component, OnInit } from '@angular/core';


export interface galleryImage {
  source: string;
  orientation: 'vertical' | 'horizontal';
}


@Component({
  selector: 'app-gallerie',
  templateUrl: './gallerie.component.html',
  styleUrls: ['./gallerie.component.scss']
})
export class GallerieComponent implements OnInit {

  constructor() { }
  showModal: boolean = false;
  modalSrc: string;
  modalOrientation: 'vertical' | 'horizontal';
  row1: galleryImage[] = [
    {source: 'https://angelinasousa.de/img/gallery/_L_L0135.jpg', orientation:'horizontal'},
    {source: 'https://angelinasousa.de/img/gallery/gallery13.JPG', orientation:'horizontal'},
    {source: 'https://angelinasousa.de/img/gallery/gallery22.JPG', orientation:'horizontal'},
    {source: 'https://angelinasousa.de/img/gallery/gallery2.JPG', orientation:'horizontal'}
  ];
  row2: galleryImage[] = [
    {source: 'https://angelinasousa.de/img/gallery/_L_L0203.jpg', orientation:'horizontal'},
    {source: 'https://angelinasousa.de/img/gallery/gallery21.JPG', orientation:'horizontal'},
    {source: 'https://angelinasousa.de/img/gallery/gallery9.JPG', orientation:'vertical'},
    {source: 'https://angelinasousa.de/img/gallery/gallery10.JPG', orientation:'horizontal'},
    {source: 'https://angelinasousa.de/img/gallery/gallery7.JPG', orientation:'horizontal'}
  ];
  row3: galleryImage[] = [
    {source: 'https://angelinasousa.de/img/gallery/gallery6.JPG', orientation:'horizontal'},
    {source: 'https://angelinasousa.de/img/gallery/gallery4.JPG', orientation:'horizontal'},
    {source: 'https://angelinasousa.de/img/gallery/gallery24.png', orientation:'horizontal'},
    {source: 'https://angelinasousa.de/img/gallery/gallery1.JPG', orientation:'vertical'},
    {source: 'https://angelinasousa.de/img/gallery/gallery14.JPG', orientation:'horizontal'}
  ];
  row4: galleryImage[] = [
    {source: 'https://angelinasousa.de/img/gallery/gallery11.JPG', orientation:'horizontal'},
    {source: 'https://angelinasousa.de/img/gallery/gallery18.JPG', orientation:'horizontal'},
    {source: 'https://angelinasousa.de/img/gallery/gallery19.JPG', orientation:'horizontal'},
    {source: 'https://angelinasousa.de/img/gallery/gallery3.JPG', orientation:'horizontal'}
  ];
  ngOnInit() {
  }

  openModal(img){
    this.showModal = true;
    this.modalSrc = img.source;
    console.log(img.orientation);
    this.modalOrientation = img.orientation;
  }

  closeModal(){
    this.showModal = false;
    // Get the modal
  }
}
